

.interior{
    overflow: hidden;
    &__top{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 20px;
    }
    &__inner{
        position: relative;
    }
    &__btns-wr{
        width: 100%;
        display: flex;
        align-items: center;
    }
    &__img{
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        height: 50vw;
        min-height: 200px;
        img{
            max-width: none;
            width: auto;
            height: 100%;
        }
    }
    &__btn{
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 150px;
        font-size: 13px;
        color: $brown;
        font-family: $ff-italic;
        line-height: 1;
        text-align: center;
        letter-spacing: 0.01em;
        border: 1px solid $brown;
        padding: 10px 5px;
        &:nth-child(2){
            margin-left: -1px;
        }
        &.active{
            background-color: $brown;
            color: $secondary;
            svg{
                fill: $secondary;
            }
        }
    }

    &__btn-icon{
        display: none;

        margin-right: 5px;
        svg{
            fill: $brown;
            width: 100%;
            max-height: 100%;
        }
        &--again{
            width: 23px;
            height: 16px;
        }

        &--img{
            width: 20px;
            height: 18px;
            svg{
                margin-top: -2px;
            }
        }
    }
    &__slider{
        margin: 0 -7.5px;
        &-item{
            padding: 0 7.5px;
        }
    }

}

@media (min-width: $btg-small) {

    .interior{
        &__img{
            img{
            }
        }
    }
}

@media (min-width: $btg-medium) {
    .interior{
        .section__title{
            margin-bottom: 0;
        }
        &__top{
            margin-bottom: 25px;
        }
        &__btns-wr{
            width: auto;
            display: flex;
        }

    }
}

@media (min-width: $IpadPro) {
    .interior{
        &__btn{
            height: 50px;
            min-width: 240px;
            font-size: 15px;
            padding: 10px 5px;
  
        }
        &__btn-icon{
            display: flex;
        }
        &__slider{
            margin: 0 -7.5px;
            &-item{
                padding: 0 7.5px;
            }
        }

    }
}

@media (min-width: $btg-extraLarge) {
    .interior{
        &__top{
            margin-bottom: 30px;
        }
        &__img{
            height: auto;
        }
    }
}


.shop{
    &__inner{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 550px;
        overflow: hidden;
        img{
            max-width: none;
            height: 100%;
            width: auto;
        }
    }
    &__link{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: rgba(250, 237, 220, 0.85);
        padding: 25px 15px;
        text-align: center;
        color: $brown;
        &--interior{
            @media (max-width: $btg-extraLarge) {
                padding:  12px 7px;
                font-size: 12px;
                .shop__icon-wr{
                    width: 43px;
                    height: 43px;
              
                }
                .shop__link-text{
                    font-size: 12px;
                    max-width: 85px;
                }
            }
            @media (min-width: $btg-medium) {
                right: 50px;
                left: auto;
                transform: none;
                transform: translateY(-50%);
            }
            @media (min-width: $btg-medium) and (max-width: 1439px) {
                padding: 25px 31px !important;
                .shop__icon-wr{
                    width: 55px;
                    height: 55px;
                }
                .shop__link-text{
                    font-size: 15px;
                    max-width: 95px;
                }
            }
            @media (min-width: $btg-extraLarge) and (max-width: 1439px) {
                padding: 27px 31px !important;
                .shop__icon-wr{
                    width: 75px;
                    height: 75px;
                }
                .shop__link-text{
                    font-size: 20px;
                    max-width: 125px;
                }
            }
        }
        &-wr{
            position: absolute;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
        }
        &::before{
            content: "";
            position: absolute;
            left: -11px;
            right: -11px;
            top: -11px;
            bottom: -11px;
            border: 3px solid rgba(250, 237, 220, 0.85);

        }
        &-text{
            font-size: 15px;
            line-height: 1.3;
            font-family: $ff-italic;
            letter-spacing: 0.02em;
            max-width: 128px;
            color: $brown;
        }
    }

    &__icon-wr{
        width: 63px;
        height: 69px;
        display: flex;
        align-items: center;
        justify-self: center;
        margin: 0 auto 3px;
        svg{
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            fill: $brown;
        }
    }
}

@media (min-width: $IpadPro) {
    .shop{
        &__inner{
            height: auto;
        }

        &__link{
            padding: 51px 32px;
            &::before{
                left: -25px;
                right: -25px;
                top: -25px;
                bottom: -25px;
                border-width: 8px;
            }
            &-text{
                font-size: 24px;
                max-width: 256px;
            }
        }

        &__icon-wr{
            width: 130px;
            height: 140px;
            margin-bottom: 10px;
        }
    }
}