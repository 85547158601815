.interior {
  overflow: hidden;
}

.interior__top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.interior__inner {
  position: relative;
}

.interior__btns-wr {
  width: 100%;
  display: flex;
  align-items: center;
}

.interior__img {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 50vw;
  min-height: 200px;
}

.interior__img img {
  max-width: none;
  width: auto;
  height: 100%;
}

.interior__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  font-size: 13px;
  color: #572B24;
  font-family: "merriweatheritalic";
  line-height: 1;
  text-align: center;
  letter-spacing: 0.01em;
  border: 1px solid #572B24;
  padding: 10px 5px;
}

.interior__btn:nth-child(2) {
  margin-left: -1px;
}

.interior__btn.active {
  background-color: #572B24;
  color: #FAEDDC;
}

.interior__btn.active svg {
  fill: #FAEDDC;
}

.interior__btn-icon {
  display: none;
  margin-right: 5px;
}

.interior__btn-icon svg {
  fill: #572B24;
  width: 100%;
  max-height: 100%;
}

.interior__btn-icon--again {
  width: 23px;
  height: 16px;
}

.interior__btn-icon--img {
  width: 20px;
  height: 18px;
}

.interior__btn-icon--img svg {
  margin-top: -2px;
}

.interior__slider {
  margin: 0 -7.5px;
}

.interior__slider-item {
  padding: 0 7.5px;
}

@media (min-width: 768px) {
  .interior .section__title {
    margin-bottom: 0;
  }

  .interior__top {
    margin-bottom: 25px;
  }

  .interior__btns-wr {
    width: auto;
    display: flex;
  }
}

@media (min-width: 1024px) {
  .interior__btn {
    height: 50px;
    min-width: 240px;
    font-size: 15px;
    padding: 10px 5px;
  }

  .interior__btn-icon {
    display: flex;
  }

  .interior__slider {
    margin: 0 -7.5px;
  }

  .interior__slider-item {
    padding: 0 7.5px;
  }
}

@media (min-width: 1200px) {
  .interior__top {
    margin-bottom: 30px;
  }

  .interior__img {
    height: auto;
  }
}

.shop__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 550px;
  overflow: hidden;
}

.shop__inner img {
  max-width: none;
  height: 100%;
  width: auto;
}

.shop__link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(250, 237, 220, 0.85);
  padding: 25px 15px;
  text-align: center;
  color: #572B24;
}

@media (max-width: 1200px) {
  .shop__link--interior {
    padding: 12px 7px;
    font-size: 12px;
  }

  .shop__link--interior .shop__icon-wr {
    width: 43px;
    height: 43px;
  }

  .shop__link--interior .shop__link-text {
    font-size: 12px;
    max-width: 85px;
  }
}

@media (min-width: 768px) {
  .shop__link--interior {
    right: 50px;
    left: auto;
    transform: none;
    transform: translateY(-50%);
  }
}

@media (min-width: 768px) and (max-width: 1439px) {
  .shop__link--interior {
    padding: 25px 31px !important;
  }

  .shop__link--interior .shop__icon-wr {
    width: 55px;
    height: 55px;
  }

  .shop__link--interior .shop__link-text {
    font-size: 15px;
    max-width: 95px;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .shop__link--interior {
    padding: 27px 31px !important;
  }

  .shop__link--interior .shop__icon-wr {
    width: 75px;
    height: 75px;
  }

  .shop__link--interior .shop__link-text {
    font-size: 20px;
    max-width: 125px;
  }
}

.shop__link-wr {
  position: absolute;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.shop__link::before {
  content: "";
  position: absolute;
  left: -11px;
  right: -11px;
  top: -11px;
  bottom: -11px;
  border: 3px solid rgba(250, 237, 220, 0.85);
}

.shop__link-text {
  font-size: 15px;
  line-height: 1.3;
  font-family: "merriweatheritalic";
  letter-spacing: 0.02em;
  max-width: 128px;
  color: #572B24;
}

.shop__icon-wr {
  width: 63px;
  height: 69px;
  display: flex;
  align-items: center;
  justify-self: center;
  margin: 0 auto 3px;
}

.shop__icon-wr svg {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  fill: #572B24;
}

@media (min-width: 1024px) {
  .shop__inner {
    height: auto;
  }

  .shop__link {
    padding: 51px 32px;
  }

  .shop__link::before {
    left: -25px;
    right: -25px;
    top: -25px;
    bottom: -25px;
    border-width: 8px;
  }

  .shop__link-text {
    font-size: 24px;
    max-width: 256px;
  }

  .shop__icon-wr {
    width: 130px;
    height: 140px;
    margin-bottom: 10px;
  }
}

.banner__item {
  margin-bottom: 15px;
  flex-grow: 1;
}

.banner__item-inner {
  position: relative;
  display: block;
}

.banner__btn {
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}

.banner__btn .btn {
  min-width: 240px;
  padding-left: 15px;
  padding-right: 15px;
  background: rgba(255, 255, 255, 0.75);
}

.banner__btn.remove {
  display: inline-block;
  cursor: pointer;
  font-size: 15px;
  line-height: 1;
  max-width: 100%;
  min-width: 240px;
  padding: 14px 5px;
  font-family: "merriweatheritalic";
  text-align: center;
  border: 0.89901px solid #572B24;
  transition: 0.3s;
}

.banner__btn.remove:hover,
.banner__btn.remove:focus {
  color: #fff;
  transition: 0.3s;
}

.banner__btn.remove:hover .banner__btn-overlay,
.banner__btn.remove:focus .banner__btn-overlay {
  display: none;
}

.banner__btn.remove:disabled {
  opacity: 0.5;
  cursor: default;
}

.banner__btn.remove span {
  position: relative;
  z-index: 2;
}

.banner__btn-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .banner {
    display: flex;
  }

  .banner__item:first-child {
    margin-right: 30px;
  }

  .banner__btn {
    bottom: 14.6%;
    left: 50%;
  }

  .banner__btn .btn {
    font-size: 18px;
    min-width: 300px;
    padding: 17px 5px;
  }
}

@media (min-width: 1440px) {
  .banner__btn .btn {
    font-size: 23px;
    letter-spacing: 0.02em;
    min-width: 428px;
    padding: 25px 5px;
  }
}

.about {
  background-color: #f9ead7;
  padding-top: 50px;
  padding-bottom: 55px;
}

.about__title {
  color: #6B4343;
  font-family: "merriweatheritalic";
  font-size: 27px;
  margin-bottom: 15px;
  letter-spacing: 0.02em;
}

@media (max-width: 576px) {
  .about__title--short {
    max-width: 250px;
  }
}

.about__desc {
  margin-bottom: 41px;
}

@media (min-width: 768px) {
  .about {
    padding-top: 65px;
    padding-bottom: 75px;
  }

  .about__inner {
    display: flex;
    align-items: center;
  }

  .about__title {
    font-size: 32px;
  }

  .about__title--short {
    max-width: none;
  }

  .about__desc {
    margin-bottom: 0;
    flex-grow: 1;
    width: 50%;
    max-width: 638px;
  }

  .about__video {
    flex-grow: 1;
    width: 50%;
    padding-left: 30px;
  }
}

@media (min-width: 1200px) {
  .about {
    padding-top: 114px;
    padding-bottom: 120px;
    position: relative;
    overflow: hidden;
  }

  .about__inner {
    position: relative;
    z-index: 2;
  }

  .about::before,
  .about::after {
    content: "";
    position: absolute;
    z-index: 1;
  }

  .about::before {
    background: url("/images/about.png") no-repeat left;
    width: 37%;
    height: 93%;
    left: 0;
    bottom: 0;
    background-size: contain;
  }

  .about::after {
    background: url("/images/about-right.png") no-repeat right;
    right: -2%;
    bottom: 0;
    width: 20%;
    height: 67%;
    background-size: contain;
  }

  .about__title {
    font-size: 42px;
  }
}

